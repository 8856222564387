html,
body {
  padding: 0;
  margin: 0;
  color: #222c33;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* modal css */

/* .transfer-modal-popup > h2.MuiTypography-root.MuiTypography-body1 {
  font-size: 17px;
  color: #444444;
  font-weight: 700;
  margin-bottom: 15px;
} */
.modal-chat-search {
  border-bottom: #e8e8e8 solid 1px;
  padding-bottom: 15px;
  position: relative;
}
.modal-chat-search svg {
  position: absolute;
  top: 8px;
  left: 10px;
}
.modal-chat-search input {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  padding: 0 20px 0 40px;
  /* font-family: "Metropolis"; */
  font-size: 13px;
  color: #444444;
}
.transfer-chat-list-wrapper {
  height: 245px;
  overflow-y: auto;
}

.transfer-chat-list {
  padding: 10px 0;
  display: flex;
  border-bottom: #e8e8e8 solid 1px;
}
@media only screen and (max-width: 280px) {
  .transfer-chat-list {
    width: 280px;
  }
}

.transfer-chat-avatar svg {
  color: #676767;
}
.transfer-chat-details {
  display: flex;
  flex-direction: column !important;
  flex: 1;
  padding: 0 15px;
  justify-content: center;
}
.transfer-chat-details p {
  font-size: 14px;
}
.transfer-chat-button {
  align-items: center;
  display: flex;
}
.transfer-chat-button button {
  background: #1c9aea;
  color: #fff !important;
  /* font-family: 'Metropolis'; */
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 3px 15px;
}
.transfer-chat-button button:hover {
  background: #1c9aea;
}
.transfer-chat-list.active .transfer-chat-avatar {
  position: relative;
}
.transfer-chat-list.active .transfer-chat-avatar::before {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #00b969;
  border: 2px solid #ffffff;
  position: absolute;
  content: "";
  top: -3px;
  right: -3px;
  z-index: 2;
}
.transfer-chat-list.deactive .transfer-chat-avatar {
  position: relative;
}
.transfer-chat-list.deactive .transfer-chat-avatar::before {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #bdbdbd;
  border: 2px solid #ffffff;
  position: absolute;
  content: "";
  top: -3px;
  right: -3px;
  z-index: 2;
}
.transfer-chat-list.deactive .transfer-chat-button button {
  background: #bdbdbd;
  /* pointer-events: none; */
  cursor: default;
}
.transfer-modal-popup > button {
  float: right;
  margin-top: 15px;
  color: #444444;
  text-transform: capitalize;
  font-weight: 700;
  /* font-family: 'Metropolis'; */
  font-size: 13px;
}

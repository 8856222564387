$primary_font: "Roboto", "Helvetica", "Arial", sans-serif;
$priamry_font_size: 13px;
$secondary_font_size: 10px;
$secondary_font_size_1: 17px;
$secondary_font_size_2: 12px;
$secondary_font_size_13: 13px;
$secondary_font_size_14: 14px;
$secondary_font_size_16: 16px;
$secondary_font_size_20: 20px;
$secondary_font_size_24: 24px;
$secondary_font_size_32: 32px;
$secondary_font_size_48: 48px;
$secondary_font_size_40: 40px;
$secondary_font_size_60: 60px;
$priamry_font-weight_1: 100;
$priamry_font-weight_2: 400;
$priamry_font-weight_3: 700;

$white_color: #fff;
$primary_color: #222c33;
$primary_text_color: #222c33;
$primary_text_color_2: #222c31;

$secondary_color: #2aaada;
$secondary_color_2: #9ccc65;
$secondary_color_3: #1c9aea;
$secondary_text_color_2: #444444;
$border_color_1: #e8e8e8;
$border_color_2: #c4c4c4;
$border_color_3: #d1d9df;
$primary_hover_color: #eeeef3;
$secondary_bg_color: #eeeef3;
$red_color: #dd3628;
$red_color_2: #e22134;
$red_color_3: #fad5d9;
$info_text_gray: #898989;
$dark_green_color: #008566;
$light_green_color: #15843c;
$fade_green_color: #edfcf2;
$green_color_200: #d3f8e0;
$success: #00b969;
$dark_yellow_color: #b47e16;
$text_yellow_color: #8c6400;
$primary_blue_color: #acdef1;
$lite_blue_color: #eef8fc;
$light_gray: #658293;
$place_holder_color: #9aadba;
$nav_icon_color: #3b4f5d;
$nav_hover_color: #1e87ae;
$button_gray: #ecf0f2;
$alert_bg_yellow: #fff7e3;
$secondary_yellow_200: #ffb300;
$grey_50: #fafbfc;
$grey_100: #ecf0f2;
$grey_200: #eff2f5;
$grey_300: #d0d9e0;
$grey_400: #7590a3;
$grey_700: #52697a;
$grey_800: #3d4f5c;
$grey_900: #1e272e;
$blue_100: #e9f5fb;
$blue_200: #67bae4;
$blue_300: #269cd9;
$blue_400: #2374c4;
$blue_500: #1e4ead;
$blue_600: #052c62;
$blue_700: #031630;
$yellow_100: #fff7e5;
$yellow_200: #f2a60d;
$yellow_300: #e5a100;
$yellow_400: #cc8800;
$yellow_500: #996100;
$yellow_600: #664400;
$green_100: #e9faef;
$green_200: #34cc74;
$green_300: #24a851;
$green_400: #1d882f;
$green_500: #166724;
$green_600: #0e4016;
$red_100: #fce8e7;
$red_200: #ec837a;
$red_300: #e05d52;
$red_400: #d64241;
$red_500: #ab2318;
$red_600: #74150d;

$text_style_i: italic;

$shadow: 0px 1px 3px rgba(61, 79, 92, 0.1),
  0px 1px 2px -1px rgba(61, 79, 92, 0.1);

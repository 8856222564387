@import "../variables";

.onboarding {
    &_dialog {
        text-align: center;
        background-color: $white_color;

        h4 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 700;
            span {
                color: $secondary_color;
            }
        }

        p {
            font-size: 14px;
            color: $grey_400;
        }

        &_skip {
            h5 {
                color: $secondary_color;
                font-size: 14px;
                font-weight: 700;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
            }
    
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: $grey_700;
            }
        }
    }

    &_tooltip {
        background-color: $white_color;
        padding: 16px 24px;
        max-width: 299px;
        position: relative;
        border-radius: 3px;

        h5 {
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.05000000074505806px;
            text-align: left;
            color: $grey_700;
        }

        span {
            color: $grey_400;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    &_button {
        &_arrow {
            height: 32px;
            width: 32px;
            border: 1px solid $button_gray;
        }
        &_done {
            height: 32px;
            width: 32px;
            background-color: $secondary_color_3;

            &:hover {
                background-color: $secondary_color_3;    
            }
        }
    }
}
@import "variables";

.main-container {
  min-height: 100vh;
  width: 100%;

  .header {
    background: $primary_color;
    padding: 7px 16px;
    position: relative;
    height: 56px;
    @media screen and (max-width: 991px) {
      padding: 7px 15px 7px 53px;
    }
    .mobile-nav {
      position: absolute;
      z-index: 9;
      top: 10px;
      left: 10px;
      @media screen and (min-width: 991px) {
        display: none;
      }
    }

    .logo-section {
      display: flex;
      align-items: center;

      .left-select {
        margin-left: 15px;
        @media screen and (max-width: 600px) {
          margin-left: 5px;
        }

        Button {
          color: $white_color;
          text-transform: capitalize;
          font-family: $primary_font;
          font-size: $priamry_font_size;
          font-weight: $priamry_font-weight_2;
          background: url(../public/images/left-arrow.png) no-repeat center
            right;
          padding-right: 15px;
          margin-right: 10px;
        }
      }
    }

    .right-part {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .select {
        Button {
          color: $white_color;
          text-transform: capitalize;

          span {
            @media screen and (max-width: 600px) {
              display: none;
            }
          }

          // &::after{
          //     width: 8px;
          //     height: 8px;
          //     border-radius: 50%;
          //     position: absolute;
          //     content: '';
          //     right: 8px;
          //     top: 5px;
          //     background: $success;
          //     border: solid 2px $primary_color;
          // }
        }
      }
      .settings {
        width: 25px;
        display: flex;
        justify-content: center;

        button {
          padding: 5px 0;
          min-width: auto;
          svg {
            fill: $white_color;
            fill-rule: evenodd;
          }
        }
      }
      .logout {
        width: 25px;
        display: flex;
        justify-content: right;
        position: relative;

        button {
          padding: 5px 0;
          min-width: auto;
          svg {
            fill: $white_color;
          }
        }
        .alert-message-con {
          position: absolute;
          width: 385px;
          right: 0;
          top: 20px;
          z-index: 10;
          font-size: $priamry_font_size;
          font-weight: $priamry_font-weight_2;
          color: $primary_text_color;
          font-family: $primary_font;
          @media screen and (max-width: 414px) {
            position: fixed;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: 60px;
            width: 90%;
          }

          .alert-message {
            border-color: $success;
            border-radius: 0 0 4px 4px;
            p {
              font-weight: $priamry_font-weight_3;
              font-family: $primary_font;
            }
            svg {
              color: $success;
            }
          }
        }
      }
    }
    .layer-overlay {
      @media screen and (max-width: 400px) {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.7;
        z-index: 200;
        transition: 0.3s;
        display: none;
      }
    }
  }

  .main-chat-section {
    display: flex;
    .chat-left {
      width: 450px;
      box-shadow: $shadow;
      z-index: 2;
      @media screen and (max-width: 1024px) {
        width: 336px;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
      }

      .chat-search {
        padding: 16px 30px;
        position: relative;
        height: 72px;
        box-shadow: $shadow;
        z-index: 2;
        @media screen and (max-width: 600px) {
          padding: 16px 15px;
        }

        input {
          width: 100%;
          height: 40px;
          padding: 0 34px 0 45px;
          border: $border_color_1 solid 1px;
          border-radius: 3px;
          font-size: $priamry_font_size;
          font-family: $primary_font;

          &:focus-visible {
            outline-color: $blue_300;
          }
        }
        .search-icon {
          position: absolute;
          top: 25px;
          left: 45px;
          @media screen and (max-width: 600px) {
            left: 30px;
          }
          @media screen and (max-width: 280px) {
            left: 30px;
          }
          svg {
            color: $primary_color;
            fill-rule: evenodd;
          }
        }
        button {
          position: absolute;
          top: 23px;
          right: 40px;
          padding: 5px;
          margin: 0;
          min-width: auto;
          @media screen and (max-width: 600px) {
            right: 25px;
          }
          @media screen and (max-width: 280px) {
            right: 25px;
          }
          svg {
            color: $primary_color;
            fill-rule: evenodd;
          }
        }
      }
      .claim {
        height: 72px;
        background: $blue_100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;

        @media screen and (max-width: 600px) {
          padding: 10px 15px;
        }

        .claim-text {
          display: flex;

          p {
            &.dig {
              font-weight: $priamry_font-weight_3;
              font-size: $secondary_font_size_24;
              color: $blue_300;
              line-height: inherit;
              @media screen and (max-width: 280px) {
                font-size: $secondary_font_size_16;
              }
            }
            &.text {
              font-weight: $priamry_font-weight_2;
              font-size: $secondary_font_size_16;
              color: $primary_text_color;
              font-family: $primary_font;
              margin-left: 5px;
              display: flex;
              align-items: flex-end;
              @media screen and (max-width: 280px) {
                font-size: $priamry_font_size;
              }
            }
          }
        }
      }
      .chat-list-wrapper {
        height: calc(100vh - 128px);
        width: calc(100% + 8px);
        padding-right: 2px;
        overflow-y: scroll;
        overflow-x: hidden;
        &.admin-wrapper {
          height: calc(100vh - 200px);
        }

        @media screen and (max-width: 991px) {
          width: 100%;
          overflow-y: auto;
          padding-right: 0;
          padding-bottom: 56px;
        }
        &:hover {
          &::-webkit-scrollbar-thumb {
            display: block;
          }
        }

        &::-webkit-scrollbar-track {
          background: rgba(255, 255, 255, 0.3);
        }
        &::-webkit-scrollbar {
          width: 6px;
          padding-right: 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: $grey_300;
          display: none;
        }

        .chat-accordion {
          margin: 0 0;
          border-radius: 0;
          border-color: $border_color_1;

          &::before {
            opacity: 0;
          }
          p {
            font-size: $priamry_font_size;
            font-family: $primary_font;
          }
          label {
            font-size: $secondary_font_size_2;
            color: $grey_400;
          }
          .MuiAccordionSummary-root {
            min-height: 52px;
            padding: 0 13px 0 30px;
          }
          .Mui-expanded {
            min-height: auto;
          }

          .chat-contents {
            .chat-contents-inner {
              display: flex;
            }
          }
          .chat-list {
            .transfer-status {
              width: 100%;
              margin-top: 10px;

              p {
                font-size: $secondary_font_size;
                color: $white_color;
                background: $dark_green_color;
                border-radius: 3px;
                padding: 3px 10px;
                font-weight: $priamry_font-weight_2;
                font-style: $text_style_i;
                display: inline-block;
              }
            }
          }
        }
        .chat-list {
          width: 100%;
          padding: 10px 10px 10px 30px;
          cursor: pointer;
          position: relative;

          &:last-child {
            &::before {
              display: none;
            }
          }
          &::before {
            width: 100%;
            height: 1px;
            position: absolute;
            content: "";
            right: 0px;
            bottom: 0;
            background: $border_color_1;
            @media screen and (max-width: 768px) {
              width: calc(100% - 40px);
            }
          }

          @media screen and (max-width: 600px) {
            padding: 10px 0px 10px 15px;
          }

          &:hover,
          &.active {
            background: $grey_200;
          }

          .chat-Avatar {
            margin-right: 10px;

            .name {
              background-color: $blue_300;
            }
            .number {
              background-color: $secondary_color_2;
            }
          }

          .chat-contents {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;

            h5 {
              font-size: $priamry_font_size;
              color: $grey_800;
              font-weight: $priamry_font-weight_3;
              display: flex;
              align-content: center;
              svg {
                color: $blue_300;
                margin-left: 2px;
              }
            }
            p {
              font-size: $secondary_font_size_2;
              color: $grey_800;
              font-weight: $priamry_font-weight_2;
              margin: 0;
            }
          }
          .chat-timing {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin-right: 5px;

            .chat-date {
              font-size: $secondary_font_size;
              color: $info_text_gray;
              font-weight: $priamry_font-weight_2;
            }
            .unread-notification {
              font-size: $secondary_font_size;
              color: $white_color;
              font-weight: $priamry_font-weight_2;
              background: $blue_300;
              border-radius: 8px;
              min-width: 16px;
              height: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              align-self: flex-end;
              padding: 0 4px;
            }
          }
          button {
            color: $primary_color;

            @media screen and (max-width: 320px) {
              padding: 0;
            }
          }
        }
        .chat-search-list-head {
          position: relative;
          &::after {
            width: 406px;
            height: 1px;
            position: absolute;
            content: "";
            left: 30px;
            bottom: 0;
            background: $border_color_2;
            @media screen and (max-width: 768px) {
              width: calc(100% - 40px);
            }
          }
          &::before {
            width: 390px;
            height: 1px;
            position: absolute;
            content: "";
            left: 30px;
            top: -1px;
            background: $border_color_2;
            @media screen and (max-width: 768px) {
              width: calc(100% - 40px);
            }
          }
          h5 {
            color: $primary_color;
          }
        }
        &.search-result {
          .chat-list {
            padding: 10px 14px 10px 30px;
            &.message {
              &::before {
                width: 406px;
                @media screen and (max-width: 768px) {
                  width: calc(100% - 40px);
                }
              }
            }
          }
        }
      }
      .no-chat {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;

        label {
          color: $grey_400;
          font-size: $secondary_font_size_14;
          font-weight: $priamry_font-weight_2;
          text-align: center;
        }
      }
    }
    .chat-right {
      flex: 1;

      @media screen and (max-width: 991px) {
        height: 100vh;
        overflow: auto;
        background: $grey_50;
      }

      .no-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: $grey_50;
        p {
          font-size: $secondary_font_size_14;
          color: $grey_400;
        }
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      @media screen and (max-width: 991px) {
        position: fixed;
        top: 0;
        width: 100%;
        left: 100%;
        transition: 0.3s;
        z-index: 10;
      }
      .back-btn {
        @media screen and (min-width: 991px) {
          display: none;
        }
      }

      .chat-right-header {
        padding: 10px 30px;
        align-items: center;
        position: relative;
        z-index: 1201;
        box-shadow: $shadow;
        background: $white_color;
        height: 72px;
        @media screen and (max-width: 991px) {
          padding: 10px;
        }
        .edit-btn {
          svg {
            fill: $primary_text_color;
          }
        }

        .chat-right-avatar {
          margin-left: 0;
        }
        .profile-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h5 {
            font-size: $secondary_font_size_14;
            color: $primary_text_color;
            font-weight: $priamry_font-weight_3;
          }
          p {
            font-size: $secondary_font_size;
            color: $primary_text_color;
            font-weight: $priamry_font-weight_2;
            margin: 0;
          }
        }
        .edit-btn {
          padding-top: 5px;
        }

        .chat-head-right-m {
          display: none;
          @media screen and (max-width: 720px) {
            display: flex;
            justify-content: flex-end;
          }
        }

        .chat-head-right {
          display: flex;
          justify-content: flex-end;
          @media screen and (max-width: 720px) {
            display: none;
          }

          .opsi-chat {
            position: relative;
            display: flex;
            justify-content: flex-end;
            flex: 1;
            // margin-right: 10px;

            .opsi-chat-inner {
              // position: absolute;
              z-index: 5;
              border: $secondary_color solid 1px;
              border-radius: 3px;
              color: $blue_300;
              background: $white_color;
              font-size: $priamry_font_size;
              font-family: $primary_font;

              button {
                text-transform: capitalize;
                color: $blue_300;

                span {
                  font-size: $priamry_font_size;
                  font-weight: $priamry_font-weight_3;
                }
                hr {
                  border-color: $blue_300;
                }
              }

              ul {
                padding: 0;

                .MuiListItem-divider {
                  border-bottom: $secondary_color solid 1px;
                  border-radius: 25px 25px 0 0;
                }

                p {
                  font-size: $priamry_font_size;
                  font-family: $primary_font;
                  font-weight: $priamry_font-weight_3;
                  text-align: right;
                }

                .opsi-chat-btn {
                  border-right: $secondary_color solid 1px;
                  padding-right: 7px;
                  margin-top: 3px;
                  margin-bottom: 3px;
                }
                li {
                  cursor: pointer;
                  padding: 0 10px;

                  p {
                    font-weight: $priamry_font-weight_1;
                    cursor: pointer;
                    text-align: right;
                  }
                }
              }
            }
          }

          .header-profile {
            position: relative;
            &::before {
              width: 2px;
              height: 40px;
              background: $border_color_2;
              position: absolute;
              content: "";
              top: -5px;
              left: -1px;
            }
            Button {
              text-transform: capitalize;
              font-size: $priamry_font_size;
              color: $blue_300;
              font-weight: $priamry_font-weight_3;
              font-family: $primary_font;
              margin: 0 10px;
              border-color: $blue_300;
              line-height: inherit;
            }
          }
          .tutup-chat {
            position: relative;

            &::after {
              width: 1px;
              height: 40px;
              background: $border_color_1;
              content: "";
              position: absolute;
              top: -5px;
              right: 22px;
            }
            Button {
              text-transform: capitalize;
              font-size: $priamry_font_size;
              color: $red_color;
              font-weight: $priamry_font-weight_3;
              font-family: $primary_font;
              margin: 0 48px 0 10px;
              border: $red_color solid 1px;
              padding: 3px 15px;
            }
          }
          .transfer-chat {
            Button {
              text-transform: capitalize;
              font-size: $priamry_font_size;
              color: $blue_300;
              font-weight: $priamry_font-weight_3;
              font-family: $primary_font;
              margin: 0 10px;
              padding: 3px 15px;
            }
          }

          .header-cart {
            display: flex;
            font-size: $priamry_font_size;
            color: $primary_text_color;
            font-weight: $priamry_font-weight_2;
            align-items: center;
            font-family: $primary_font;

            button {
              border: none;
              background: none;
              padding: 0;
              display: flex;
              cursor: pointer;

              svg {
                fill: $primary_text_color;
                fill-rule: evenodd;
              }

              .cart-num {
                padding: 2px 0px 0px 5px;
              }
            }
          }
        }
      }

      .chat-section {
        background: $grey_50;
        height: calc(100vh - 128px);
        padding: 0px 30px 67px 30px;
        position: relative;
        @media screen and (min-width: 1200px) {
          padding-left: 103px;
          padding-right: 103px;
        }
        @media screen and (max-width: 600px) {
          padding: 20px 15px 135px 15px;
        }
        @media screen and (max-width: 991px) {
          height: calc(100vh - 72px);
          height: calc((var(--vh, 1vh) * 100) - 72px);
        }

        .chat-section-wrapper {
          height: 100%;
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-end;
          margin-right: -10px;

          @media screen and (max-width: 600px) {
            margin-right: 0;
          }

          .chat-section-inner {
            overflow-y: auto;
            display: flex;
            flex-direction: column-reverse;
            padding-right: 2px;
            padding-bottom: 55px;
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none;

            @media screen and (max-width: 600px) {
              padding-bottom: 0;
            }

            &:hover {
              &::-webkit-scrollbar-thumb {
                display: block;
              }
            }

            &::-webkit-scrollbar-track {
              background-color: #eeeef3;
            }
            &::-webkit-scrollbar {
              width: 0;
              background: transparent;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: $info_text_gray;
              display: none;
            }

            .received-message {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .image-text {
                width: 242px;
                height: auto;
                padding: 8px;
                background: $white_color;
                border-radius: 8px 8px 8px 0px;
                display: flex;
                margin-top: 8px;
                flex-direction: column;
                box-shadow: $shadow;
                .image-container {
                  display: inline-flex;
                  position: relative;
                  justify-content: center;
                  align-items: center;
                  img {
                    border-radius: 4px;
                  }
                  .download-notification {
                    display: none;
                  }
                  .upload-notification {
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    padding: 10px 16px;
                    color: $white_color;
                    background: rgba(34, 44, 51, 0.6);
                    border-radius: 4px;
                    pointer-events: none;
                    p {
                      font-size: $secondary_font_size_16;
                      font-weight: $priamry_font-weight_3;
                      margin-left: 8px;
                    }
                  }
                }
              }
              .message {
                background: $white_color;
                font-family: $primary_font;
                font-size: $priamry_font_size;
                color: $grey_800;
                font-weight: $priamry_font-weight_2;
                box-shadow: $shadow;
                padding: 8px 16px;
                border-radius: 8px 8px 8px 0px;
                max-width: 500px;
                word-break: break-word;
                margin-top: 8px;
                &.caption {
                  box-shadow: none !important;
                  margin: 0;
                  padding: 8px 8px 0 8px;
                }
                a {
                  color: $blue_300;
                }

                @media screen and (max-width: 900px) {
                  max-width: 270px;
                }
              }
              .message-status {
                font-family: $primary_font;
                font-size: $secondary_font_size;
                color: $grey_400;
                font-weight: $priamry_font-weight_1;
                padding: 5px 0;
              }
              .image {
                width: 242px;
                height: 242px;
                padding: 8px;
                background: $white_color;
                border-radius: 8px 8px 8px 0px;
                display: flex;
                margin-top: 8px;
                box-shadow: $shadow;

                .image-container {
                  display: inline-flex;
                  position: relative;
                  justify-content: center;
                  align-items: center;
                  img {
                    border-radius: 4px;
                  }
                  .download-notification {
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    padding: 10px 16px;
                    color: $white_color;
                    background: rgba(34, 44, 51, 0.6);
                    border-radius: 4px;
                    pointer-events: none;
                    p {
                      font-size: $secondary_font_size_16;
                      font-weight: $priamry_font-weight_3;
                      margin-left: 8px;
                    }
                  }
                  .upload-notification {
                    display: none;
                  }
                }
              }
              .multiple-image {
                margin-top: 8px;
                width: 242px;
                height: auto;
                padding: 8px;
                background: $white_color;
                border-radius: 8px 8px 8px 0px;
                box-shadow: $shadow;

                .image-container {
                  display: inline-flex;
                  position: relative;
                  justify-content: center;
                  align-items: center;
                  padding: 2px;
                  img {
                    border-radius: 4px;
                  }
                  .more-image {
                    position: absolute;
                    height: calc(100% - 4px);
                    width: calc(100% - 4px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $white_color;
                    background: rgba(34, 44, 51, 0.6);
                    border-radius: 4px;
                    pointer-events: none;
                    p {
                      font-size: $secondary_font_size_24;
                    }
                  }
                }
              }
              .document {
                width: 242px;
                padding: 8px;
                background: $white_color;
                border-radius: 8px 8px 8px 0px;
                margin-top: 8px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                box-shadow: $shadow;
                .pdf-document {
                  width: 100%;
                  height: 111px;
                  div {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    overflow: hidden;
                    img {
                      object-fit: cover;
                    }
                  }
                }
                .doc-description {
                  display: flex;
                  justify-content: space-between;
                  .text {
                    display: flex;
                    flex-direction: column;
                    h5 {
                      font-size: $secondary_font_size_16;
                      font-weight: $priamry_font-weight_3;
                      color: $grey_800;
                      line-height: 1;
                    }
                    p {
                      font-size: $secondary_font_size_2;
                      font-weight: $priamry_font-weight_2;
                      color: $grey_800;
                      line-height: 1;
                      margin-top: 2px;
                    }
                  }
                  .download-icon {
                    .upload {
                      display: none;
                    }
                    svg {
                      fill: $grey_700;
                    }
                  }
                }
              }
            }

            .send-message {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              .message {
                background: $blue_100;
                font-family: $primary_font;
                font-size: $priamry_font_size;
                color: $grey_800;
                font-weight: $priamry_font-weight_2;
                box-shadow: $shadow;
                padding: 8px 16px;
                border-radius: 8px 8px 0px 8px;
                max-width: 500px;
                word-break: break-word;
                margin-top: 8px;
                a {
                  color: $blue_300;
                }

                &.caption {
                  box-shadow: none !important;
                  margin: 0;
                  padding: 8px 8px 0 8px;
                }

                @media screen and (max-width: 600px) {
                  max-width: 250px;
                }
              }
              .message-status {
                font-family: $primary_font;
                font-size: $secondary_font_size;
                color: $grey_400;
                font-weight: $priamry_font-weight_1;
                padding: 5px 0;
                display: flex;
                align-items: center;

                .message-status-icon {
                  color: $grey_700;
                  margin-left: 5px;
                  display: flex;
                  align-items: center;

                  &.failed {
                    color: $red_300;
                    & > p {
                      margin: 0;
                      margin-right: 2px;
                    }
                  }
                }
                .status-icon {
                  font-size: 20px;
                  font-weight: bold;
                }
                .receive {
                  .status-icon {
                    &:first-child {
                      margin-right: -13px;
                    }
                  }
                }
                .read {
                  .status-icon {
                    color: $blue_300;
                  }
                }
              }
              .image {
                width: 242px;
                height: 242px;
                padding: 8px;
                background: $blue_100;
                border-radius: 8px 8px 0px 8px;
                display: flex;
                margin-top: 8px;
                box-shadow: $shadow;

                .image-container {
                  display: inline-flex;
                  position: relative;
                  justify-content: center;
                  align-items: center;
                  img {
                    border-radius: 4px;
                  }
                  .download-notification {
                    display: none;
                  }
                  .upload-notification {
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    padding: 10px 16px;
                    color: $white_color;
                    background: rgba(34, 44, 51, 0.6);
                    border-radius: 4px;
                    pointer-events: none;
                    p {
                      font-size: $secondary_font_size_16;
                      font-weight: $priamry_font-weight_3;
                      margin-left: 8px;
                    }
                  }
                }
              }
              .image-text {
                width: 242px;
                height: auto;
                padding: 8px;
                background: $blue_100;
                color: $primary_text_color;
                border-radius: 8px 8px 0px 8px;
                display: flex;
                margin-top: 8px;
                flex-direction: column;
                box-shadow: $shadow;

                .image-container {
                  display: inline-flex;
                  position: relative;
                  justify-content: center;
                  align-items: center;
                  img {
                    border-radius: 4px;
                  }
                  .download-notification {
                    display: none;
                  }
                  .upload-notification {
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    padding: 10px 16px;
                    color: $white_color;
                    background: rgba(34, 44, 51, 0.6);
                    border-radius: 4px;
                    pointer-events: none;
                    p {
                      font-size: $secondary_font_size_16;
                      font-weight: $priamry_font-weight_3;
                      margin-left: 8px;
                    }
                  }
                }
              }
              .multiple-image {
                margin-top: 8px;
                width: 242px;
                height: auto;
                padding: 8px;
                background: $blue_100;
                border-radius: 8px 8px 0px 8px;
                box-shadow: $shadow;

                .image-container {
                  display: inline-flex;
                  position: relative;
                  justify-content: center;
                  align-items: center;
                  padding: 2px;
                  img {
                    border-radius: 4px;
                  }
                  .more-image {
                    position: absolute;
                    height: calc(100% - 4px);
                    width: calc(100% - 4px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $white_color;
                    background: rgba(34, 44, 51, 0.6);
                    border-radius: 4px;
                    pointer-events: none;
                    p {
                      font-size: $secondary_font_size_24;
                    }
                  }
                }
              }
              .document {
                width: 242px;
                padding: 8px;
                background: $blue_100;
                border-radius: 8px 8px 0px 8px;
                margin-top: 8px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                box-shadow: $shadow;
                .pdf-document {
                  width: 100%;
                  height: 111px;
                  div {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    overflow: hidden;
                    img {
                      object-fit: cover;
                    }
                  }
                }
                .doc-description {
                  display: flex;
                  justify-content: space-between;
                  .text {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    h5 {
                      font-size: $secondary_font_size_16;
                      font-weight: $priamry_font-weight_3;
                      color: $grey_800;
                      line-height: 1;
                    }
                    p {
                      font-size: $secondary_font_size_2;
                      font-weight: $priamry_font-weight_2;
                      color: $grey_800;
                      line-height: 1;
                      margin-top: 2px;
                    }
                  }
                  .download-icon {
                    .download {
                      display: none;
                    }
                    svg {
                      fill: $white_color;
                    }
                  }
                }
              }
            }
          }
        }

        .chat-text {
          position: absolute;
          z-index: 10;
          bottom: 20px;
          width: calc(100% - 52px);
          @media screen and (min-width: 1200px) {
            width: calc(100% - 199px);
          }
          @media screen and (max-width: 768px) {
            // position: fixed;
          }
          @media screen and (max-width: 600px) {
            width: calc(100% - 30px);
          }

          &.drawer {
            width: calc(100% - 52px);
          }

          textarea {
            resize: none;
            width: 100%;
            background-color: $white_color;
            border-radius: 3px;
            border: none;
            padding: 15px 125px 15px 60px;
            font-family: $primary_font;
            font-size: $priamry_font_size;
            height: 40px;
            color: $primary_text_color;
            box-shadow: $shadow;
            &.disable {
              padding: 15px 125px 15px 15px;
            }

            &:focus-visible {
              outline-color: $blue_300;
            }
          }
          .app-btn {
            display: inline-block;
            position: absolute;
            background: $white_color;
            bottom: 12px;
            left: 7px;
            &::after {
              width: 1px;
              height: 24px;
              position: absolute;
              content: "";
              right: -5px;
              top: 7px;
              background-color: $border_color_3;
            }
          }
          .chat-buttons {
            position: absolute;
            right: 10px;
            bottom: 10px;

            svg {
              fill: $grey_400;
              fill-rule: evenodd;
            }

            .send-message-icon {
              svg {
                fill: $blue_300;
              }
            }

            button {
              padding: 0 10px;
              min-width: auto;

              svg {
                fill: $blue_300;
                fill-rule: evenodd;
              }
            }
          }
          .picker-closed {
            display: none;
          }
          .picker-open {
            float: right;
            margin-bottom: 50px;
          }
          .emoji-mart-preview {
            display: none;
          }
        }
      }
    }
    .left-nav-wrapper {
      width: 64px;
      box-shadow: 0px 2px 8px rgba(61, 79, 92, 0.1);
      -webkit-box-shadow: 0px 2px 8px rgba(61, 79, 92, 0.1);
      -moz-box-shadow: 0px 2px 8px rgba(61, 79, 92, 0.1);
      z-index: 1201;
      @media screen and (max-width: 991px) {
        position: absolute;
        width: 256px;
        top: 53px;
        height: 100%;
        left: -256px;
        // left: 0;
        transition: 0.3s;
        z-index: 1201;
      }
      @media screen and (max-width: 400px) {
        top: 0px;
      }
      @media screen and (max-width: 290px) {
        width: 100%;
      }

      .left-nav {
        background: $white_color;
        width: 64px;
        z-index: 5;
        overflow: hidden;
        height: 100%;
        transition: 0.3s;
        @media screen and (max-width: 991px) {
          position: relative;
          width: 100%;
          padding-top: 0px;
        }
        @media screen and (max-width: 400px) {
          padding-top: 50px;
        }
        &:hover {
          width: 256px;
          box-shadow: 0px 2px 8px rgba(61, 79, 92, 0.1);
          -webkit-box-shadow: 0px 2px 8px rgba(61, 79, 92, 0.1);
          -moz-box-shadow: 0px 2px 8px rgba(61, 79, 92, 0.1);

          .left-nav-inner {
            nav {
              ul {
                li {
                  display: flex;
                  .nav-list-btn {
                    width: 100%;
                    .left-nav-text {
                      display: block;
                      transition: 0.3s;
                    }
                  }
                }
              }
            }
          }
        }
        .left-nav-inner {
          width: 256px;
          nav {
            ul {
              li {
                display: block;
                transition: 0.3s;
                @media screen and (max-width: 991px) {
                  display: flex;
                }

                .nav-list-btn {
                  border-radius: 4px;
                  padding: 8px 14px;
                  width: 48px;
                  height: 48px;
                  transition: 0.3s;
                  @media screen and (max-width: 991px) {
                    width: 100%;
                  }
                  svg {
                    fill: $nav_icon_color;
                    fill-rule: evenodd;
                  }
                  .left-nav-text {
                    margin-left: 13px;
                    display: none;
                    transition: 0.3s;
                    @media screen and (max-width: 991px) {
                      display: block;
                    }
                  }
                  span {
                    color: $primary_text_color;
                  }
                  &:hover {
                    background-color: transparent;
                    svg {
                      fill: $blue_400;
                    }
                    span {
                      color: $blue_400;
                    }
                  }
                  &.active {
                    background-color: $blue_100;
                    svg {
                      fill: $blue_300;
                    }
                    span {
                      color: $blue_300;
                      font-weight: $priamry_font-weight_3;
                    }
                  }
                }
              }
            }
          }
        }
        .close-nav-btn {
          display: none;
          @media screen and (max-width: 400px) {
            position: absolute;
            top: 12px;
            right: 12px;
            display: block;
          }
        }
      }
    }
  }

  .inner-page-section {
    width: 100%;
    min-height: calc(100vh - 56px);
    background: $button_gray;

    .inner-page-header {
      height: 54px;
      background: $white_color;
      width: 100%;
      padding: 0 32px;
      display: flex;
      align-items: center;
      position: relative;
      @media screen and (max-width: 1024px) {
        padding: 0 16px;
      }

      .back-nav {
        display: none;
        margin-right: 16px;
        @media screen and (max-width: 414px) {
          display: block;
        }
      }

      h5 {
        font-size: $secondary_font_size_16;
        color: $primary_text_color;
        font-weight: $priamry_font-weight_3;
      }
      @media screen and (max-width: 414px) {
        padding: 16px;
      }
    }
    .home-section {
      @media screen and (max-width: 450px) {
        display: none;
      }
      min-height: calc(100vh - 110px);

      .tab-wrapper {
        .tab-section {
          padding: 0;
        }
        label {
          font-size: $secondary_font_size_2;
          font-weight: $priamry_font-weight_2;
          color: $primary_text_color;
          svg {
            margin-left: 5px;
          }
        }
        .tab {
          padding: 0 32px;
          background: $white_color;
          @media screen and (max-width: 1024px) {
            padding: 0 16px;
          }
          .css-1aquho2-MuiTabs-indicator {
            height: 4px;
            border-radius: 5px 5px 0 0;
            background-color: $blue_300;
          }

          button {
            padding: 12px 0;
            margin-right: 32px;
            text-transform: capitalize;
            font-size: $secondary_font_size_14;
            font-weight: $priamry_font-weight_3;
            &.Mui-selected {
              color: $blue_300;
            }
          }
        }
        .performance-wrapper {
          padding: 20px 32px;
          @media screen and (max-width: 1024px) {
            padding: 20px 16px;
          }
          h5 {
            font-size: $secondary_font_size_16;
            color: $primary_text_color;
            font-weight: $priamry_font-weight_3;
          }
          p {
            font-size: $secondary_font_size_2;
            color: $grey_400;
          }
          .period-select-section {
            display: flex;
            @media screen and (max-width: 414px) {
              flex-direction: column;
            }
            .select {
              width: 216px;
              margin-right: 20px;
              @media screen and (max-width: 414px) {
                width: 100%;
              }
              .MuiSelect-select {
                padding: 8px 16px;
                background: $white_color;
              }
              input {
                height: 40px;
                padding: 0 16px;
                color: $primary_text_color;
              }
              svg {
                fill: $nav_icon_color;
              }
            }
          }
          .performance-table-wrapper {
            .performance-table-container {
              scrollbar-color: $info_text_gray;
              scrollbar-width: thin;

              &::-webkit-scrollbar-track {
                background: rgba(255, 255, 255, 0.3);
              }
              &::-webkit-scrollbar {
                width: 5px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: $info_text_gray;
              }

              .text-blur {
                color: transparent;
                text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
              }
            }

            table {
              thead {
                th {
                  font-size: $secondary_font_size_2;
                  font-weight: $priamry_font-weight_3;
                  padding: 12px 8px;
                }
              }
              td {
                font-size: $secondary_font_size_2;
                font-weight: $priamry_font-weight_2;
                padding: 12px 8px;
              }
            }
          }
        }
        .general-wrapper {
          padding: 20px 32px;
          @media screen and (max-width: 1024px) {
            padding: 20px 16px;
          }
        }
      }
      .date-picker-wrapper {
        .date-picker {
          display: flex;
          @media screen and (max-width: 300px) {
            flex-direction: column;
          }
          .date-picker-block {
            position: relative;
            @media screen and (max-width: 300px) {
              max-width: 100%;
            }
            svg {
              position: absolute;
              left: 20px;
              top: 18px;
              z-index: 1;
            }
          }
          input {
            height: 40px;
            padding: 0 0px 0 40px;
            border-color: $border_color_3;
            background: $white_color;
          }
        }
      }
      .conversations {
        width: 1010px;
        margin-top: 16px;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        .conversations-blocks {
          background: $white_color;
          padding: 16px;
          text-align: center;
          border-radius: 4px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          height: 100%;
          h3 {
            line-height: 1;
            font-size: $secondary_font_size_32;
            font-weight: $priamry_font-weight_3;
            color: $blue_300;
            margin-bottom: 10px;
          }
          p {
            font-size: $secondary_font_size_16;
            font-weight: $priamry_font-weight_2;
            color: $primary_text_color;
          }
        }
        .w-100 {
          @media screen and (max-width: 300px) {
            max-width: 100%;
            flex-basis: 100%;
          }
        }
      }
    }

    .m-home-section {
      @media screen and (min-width: 451px) {
        display: none;
      }
      .m-accordion {
        border-radius: 0;
        box-shadow: none;
        margin: 0;
        border-top: $border_color_3 solid 1px;

        .accordion-head {
          min-height: 48px;
          .css-o4b71y-MuiAccordionSummary-content {
            margin: 12px 0;
          }
          p {
            font-weight: $priamry_font-weight_3;
            font-size: $secondary_font_size_14;
          }
        }
        .accordion-content {
          padding: 0;
          label {
            font-size: $secondary_font_size_14;
            font-weight: $priamry_font-weight_2;
            color: $grey_800;
          }
          .general-wrapper {
            padding: 24px;
          }
          .date-picker-wrapper {
            .date-picker {
              display: flex;
              @media screen and (max-width: 300px) {
                flex-direction: column;
              }
              .date-picker-block {
                position: relative;
                @media screen and (max-width: 300px) {
                  max-width: 100%;
                }
                svg {
                  position: absolute;
                  left: 20px;
                  top: 18px;
                  z-index: 1;
                }
              }
              input {
                height: 40px;
                padding: 0 0px 0 40px;
                border-color: $border_color_3;
                background: $white_color;
              }
            }
          }
          .select {
            width: 216px;
            margin-bottom: 20px;
            @media screen and (max-width: 414px) {
              width: 100%;
            }
            .MuiSelect-select {
              padding: 8px 16px;
              background: $white_color;
            }
            input {
              height: 40px;
              padding: 0 16px;
              color: $primary_text_color;
            }
          }
          .performance-wrapper {
            padding: 24px;
            h5 {
              font-size: $secondary_font_size_16;
              color: $primary_text_color;
              font-weight: $priamry_font-weight_3;
            }
            p {
              font-size: $priamry_font_size;
              color: $grey_400;
            }
            .performance-table-wrapper {
              .performance-table-container {
                scrollbar-color: $info_text_gray;
                scrollbar-width: thin;

                &::-webkit-scrollbar-track {
                  background: rgba(255, 255, 255, 0.3);
                }
                &::-webkit-scrollbar {
                  width: 5px;
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  background-color: $info_text_gray;
                }

                .text-blur {
                  color: transparent;
                  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
                }
              }
              table {
                thead {
                  th {
                    font-size: $secondary_font_size_2;
                    font-weight: $priamry_font-weight_3;
                    padding: 12px 8px;
                  }
                }
                td {
                  font-size: $secondary_font_size_2;
                  font-weight: $priamry_font-weight_2;
                  padding: 12px 8px;
                }
              }
            }
          }
          .conversations {
            width: 1010px;
            margin-top: 16px;
            @media screen and (max-width: 1024px) {
              width: 100%;
            }
            .conversations-blocks {
              background: $white_color;
              padding: 16px;
              text-align: center;
              border-radius: 4px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
              height: 100%;
              h3 {
                line-height: 1;
                font-size: $secondary_font_size_32;
                font-weight: $priamry_font-weight_3;
                color: $blue_300;
                margin-bottom: 10px;
              }
              p {
                font-size: $secondary_font_size_16;
                font-weight: $priamry_font-weight_2;
                color: $primary_text_color;
              }
            }
            .w-100 {
              @media screen and (max-width: 300px) {
                max-width: 100%;
                flex-basis: 100%;
              }
            }
          }
        }
      }
    }
    .arrangement-section {
      min-height: calc(100vh - 110px);
      @media screen and (max-width: 450px) {
        display: none;
      }

      .tab-wrapper {
        .tab-section {
          padding: 32px;
          height: 100%;
          @media screen and (max-width: 1024px) {
            padding: 16px;
          }
          .tab-section-inner {
            .bot-menu {
              .bot-menu-top {
                h5 {
                  line-height: 32px;
                  font-size: $secondary_font_size_20;
                  font-weight: $priamry_font-weight_3;
                  margin-bottom: 8px;
                }
                p {
                  font-size: $secondary_font_size_14;
                  font-weight: $priamry_font-weight_2;
                  color: $light_gray;
                  a {
                    text-decoration: none;
                    color: $blue_300;
                  }
                }
              }
              .bot-menu-notification {
                background: $button_gray;
                padding: 12px;
                border-radius: 4px;
                display: flex;
                label {
                  font-size: $secondary_font_size_2;
                  font-weight: $priamry_font-weight_3;
                  color: $nav_icon_color;
                  margin-right: 8px;
                }
                p {
                  font-size: $secondary_font_size_2;
                  font-weight: $priamry_font-weight_2;
                  color: $nav_icon_color;
                }
              }
              .create-bot-conversation {
                width: 100%;
                min-height: 525px;
                padding: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .create-bot-conversation-inner {
                  width: auto;
                  text-align: center;
                  h5 {
                    margin: 10px 0;
                    font-size: $secondary_font_size_16;
                    font-weight: $priamry_font-weight_3;
                    color: $secondary_text_color_2;
                  }
                  p {
                    font-size: $secondary_font_size_16;
                    font-weight: $priamry_font-weight_2;
                    color: $nav_icon_color;
                  }
                }
                &.bot-conversation-flow {
                  display: block;
                  h5 {
                    font-size: $secondary_font_size_16;
                    font-weight: $priamry_font-weight_3;
                    color: $secondary_text_color_2;
                  }
                  .add-bot-section {
                    margin-top: 24px;
                    .add-bot-block {
                      .bot {
                        padding: 8px 24px;
                        border: $border_color_3 solid 1px;
                        border-radius: 4px;
                        margin-bottom: 8px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h4 {
                          font-size: $secondary_font_size_16;
                          font-weight: $priamry_font-weight_2;
                          color: $primary_text_color;
                          display: flex;
                          align-items: center;
                          svg {
                            margin-right: 8px;
                          }
                        }
                        label {
                          font-size: $secondary_font_size;
                          font-weight: $priamry_font-weight_2;
                          color: $blue_300;
                        }
                        .mlcbsvg {
                          background: none;
                          border: none;
                          cursor: pointer;
                          &.addclass {
                            svg {
                              transform: rotate(180deg);
                            }
                          }
                        }
                      }
                      .active {
                        height: 72px;
                        overflow: hidden;
                      }
                    }
                  }
                }
              }
            }
            .welcome-message {
              @media screen and (max-width: 768px) {
                width: 100%;
              }
              P {
                font-size: $secondary_font_size_14;
                font-weight: $priamry_font-weight_2;
                margin-bottom: 16px;
                color: $light_gray;
              }
              label {
                font-size: $secondary_font_size_2;
                font-weight: $priamry_font-weight_2;
              }
              .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
                padding: 10px 14px;
              }
              .upload-text {
                @media only screen and (max-width: 599px) {
                  margin-left: 0 !important;
                }
              }
            }
            .operational-hour {
              @media screen and (max-width: 768px) {
                width: 100%;
              }
              P {
                font-size: $secondary_font_size_14;
                font-weight: $priamry_font-weight_2;
                margin-bottom: 16px;
                color: $light_gray;
              }
              label {
                font-size: $secondary_font_size_2;
                font-weight: $priamry_font-weight_2;
                color: $primary_text_color;
              }
              .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
                padding: 10px 14px;
              }
              .accordion-wrapper {
                margin-top: 16px;
                .accordion {
                  margin: 0;
                  border-radius: 0;
                  border-top: $border_color_3 solid 1px;
                  &:last-child {
                    border-bottom: $border_color_3 solid 1px;
                  }
                  p {
                    margin-bottom: 0;
                  }
                  h5 {
                    font-size: $secondary_font_size_16;
                    font-weight: $priamry_font-weight_3;
                  }
                  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
                    min-height: auto;
                    margin: 8px 0;
                  }
                  .css-b6ordg-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
                    min-height: 48px;
                  }
                  .time-picker-wrapper {
                    .time-picker {
                      display: flex;
                      flex-direction: column;
                      width: 150px;
                      .picker {
                        position: relative;
                        &::after {
                          width: 20px;
                          height: 20px;
                          position: absolute;
                          content: "";
                          right: 10px;
                          top: 10px;
                          background: url(../public/images/timepicker.png)
                            no-repeat center right;
                          pointer-events: none;
                        }
                      }
                      input {
                        padding: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .tab {
          padding: 0 32px;
          background: $white_color;
          @media screen and (max-width: 1024px) {
            padding: 0 16px;
          }
          .css-1aquho2-MuiTabs-indicator {
            height: 4px;
            border-radius: 5px 5px 0 0;
            background-color: $blue_300;
          }
          button {
            padding: 12px 0;
            margin-right: 32px;
            text-transform: capitalize;
            font-size: $secondary_font_size_14;
            font-weight: $priamry_font-weight_3;
            &.Mui-selected {
              color: $blue_300;
            }
          }
        }
      }
    }
    .m-arrangement-section {
      @media screen and (min-width: 451px) {
        display: none;
      }
      .m-accordion {
        border-radius: 0;
        box-shadow: none;
        margin: 0;
        border-top: $border_color_3 solid 1px;

        .accordion-head {
          min-height: 48px;
          .css-o4b71y-MuiAccordionSummary-content {
            margin: 12px 0;
          }
          p {
            font-weight: $priamry_font-weight_3;
            font-size: $secondary_font_size_14;
          }
        }

        .accordion-details {
          padding: 0;
        }

        .welcome-message {
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          P {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
            margin-bottom: 16px;
            color: $light_gray;
            line-height: 24px;
          }
          label {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
          }
          .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
            padding: 10px 14px;
          }
          .upload-text {
            @media only screen and (max-width: 599px) {
              margin-left: 0 !important;
            }
          }
        }

        .operational-hour {
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          P {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
            margin-bottom: 16px;
            color: $light_gray;
            line-height: 24px;
          }
          label {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
          }
          .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
            padding: 10px 14px;
          }
          .accordion-wrapper {
            margin-top: 16px;
            .accordion {
              margin: 0;
              border-radius: 0;
              border-top: $border_color_3 solid 1px;
              &:last-child {
                border-bottom: $border_color_3 solid 1px;
              }
              p {
                margin-bottom: 0;
              }
              h5 {
                font-size: $secondary_font_size_16;
                font-weight: $priamry_font-weight_3;
              }
              .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
                min-height: auto;
                margin: 8px 0;
              }
              .css-b6ordg-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
                min-height: 48px;
              }
              .time-picker-wrapper {
                .time-picker {
                  display: flex;
                  flex-direction: column;
                  width: 150px;
                  input {
                    padding: 8px;
                  }
                }
              }
            }
          }
        }
        .bot-menu {
          padding: 24px;
          .bot-menu-top {
            h5 {
              font-size: $secondary_font_size_16;
              font-weight: $priamry_font-weight_3;
              margin-bottom: 8px;
            }
            p {
              font-size: $secondary_font_size_2;
              font-weight: $priamry_font-weight_2;
              color: $light_gray;
              line-height: 24px;
            }
          }
          .bot-menu-notification {
            background: $button_gray;
            padding: 12px;
            border-radius: 4px;
            display: flex;
            @media screen and (max-width: 414px) {
              flex-direction: column;
            }
            label {
              font-size: $secondary_font_size_2;
              font-weight: $priamry_font-weight_3;
              color: $nav_icon_color;
              margin-right: 8px;
            }
            p {
              font-size: $secondary_font_size_2;
              font-weight: $priamry_font-weight_2;
              color: $nav_icon_color;
            }
          }
          .create-bot-conversation {
            width: 100%;
            padding: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 450px) {
              padding: 24px 0px;
            }

            .create-bot-conversation-inner {
              width: auto;
              text-align: center;
              h5 {
                margin: 10px 0;
                font-size: $secondary_font_size_16;
                font-weight: $priamry_font-weight_3;
                color: $secondary_text_color_2;
              }
              p {
                font-size: $secondary_font_size_2;
                font-weight: $priamry_font-weight_2;
                color: $nav_icon_color;
              }
              button {
                margin-top: 16px;
                font-weight: $priamry_font-weight_3;
              }
            }
            &.bot-conversation-flow {
              display: block;
              @media screen and (max-width: 450px) {
                padding: 0px;
              }
              h5 {
                font-size: $secondary_font_size_16;
                font-weight: $priamry_font-weight_3;
                color: $secondary_text_color_2;
              }
              .add-bot-section {
                margin-top: 24px;
                .add-bot-block {
                  .bot {
                    padding: 8px 24px;
                    border: $border_color_3 solid 1px;
                    border-radius: 4px;
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    overflow: auto;
                    height: 72px;
                    @media screen and (max-width: 280px) {
                      padding: 8px;
                    }

                    h4 {
                      font-size: $secondary_font_size_16;
                      font-weight: $priamry_font-weight_2;
                      color: $primary_text_color;
                      display: flex;
                      align-items: center;
                      svg {
                        margin-right: 8px;
                      }
                    }
                    label {
                      font-size: $secondary_font_size;
                      font-weight: $priamry_font-weight_2;
                      color: $blue_300;
                    }
                    .mlcbsvg {
                      background: none;
                      border: none;
                      cursor: pointer;
                      &.addclass {
                        svg {
                          transform: rotate(180deg);
                        }
                      }
                    }
                  }
                  .active {
                    height: 80px;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
        .quick-reply {
          width: 1088px;
          background: $white_color;
          border-radius: 4px;
          padding: 0px;
          @media screen and (max-width: 1180px) {
            width: 100%;
          }
          .quick-reply-top {
            h5 {
              font-size: $secondary_font_size_14;
              font-weight: $priamry_font-weight_3;
              margin-bottom: 8px;
            }
            p {
              font-size: $secondary_font_size_2;
              font-weight: $priamry_font-weight_2;
              a {
                text-decoration: none;
                color: $blue_300;
              }
            }
            button {
              height: 30px !important;
              font-size: $secondary_font_size_2 !important;
            }
            .Sync-btn {
              @media screen and (max-width: 768px) {
                display: none;
              }
            }
            .Sync-btn-icon {
              margin-right: 16px;
              @media screen and (min-width: 769px) {
                display: none;
              }
            }
          }
          .quick-reply-table-container {
            thead {
              @media screen and (max-width: 600px) {
                display: none;
              }
              th {
                padding: 16px 8px 16px 0;
              }
            }
            .quick-reply-table-body {
              @media screen and (max-width: 600px) {
                display: none;
              }
              td {
                padding: 16px 8px 16px 0;
                .action-btns {
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                }
              }
            }
            .m-quick-reply-table-body {
              @media screen and (min-width: 601px) {
                display: none;
              }
              tr {
                display: flex;
                flex-direction: column;
                width: 100%;
                td {
                  width: 100% !important;
                  padding: 8px 0px 8px 0;
                  display: flex;
                  justify-content: space-between;
                  .message-content {
                    width: 60%;
                  }
                  .action-btns {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                  }
                  &:first-child {
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .conversation-channel-wrapper {
      padding: 32px;
      @media screen and (max-width: 992px) {
        padding: 16px;
      }
      @media screen and (max-width: 599px) {
        padding-top: 27px;
      }
      p {
        font-size: $secondary_font_size_16;
        font-weight: $priamry_font-weight_2;
        color: $primary_text_color;
        @media screen and (max-width: 599px) {
          font-size: $secondary_font_size_2;
        }
        span {
          font-weight: $priamry_font-weight_3;
        }
      }
      .conversation-list {
        @media screen and (min-width: 1400px) {
          width: 90%;
        }
        @media screen and (max-width: 899px) {
          width: 90%;
        }
        @media screen and (max-width: 599px) {
          width: inherit;
        }

        .conversation-box {
          height: 145px;
          background: $white_color;
          border-radius: 4px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @media screen and (max-width: 820px) {
            height: 125px;
          }
          @media screen and (max-width: 599px) {
            flex-direction: row;
            height: 95px;
            align-items: center;
          }
          button {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            border: $secondary_color solid 1px;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            &:hover {
              text-decoration: none;
            }
            @media screen and (max-width: 599px) {
              width: 100%;
            }
            p {
              text-transform: capitalize;
              font-size: $secondary_font_size_14;
              font-weight: $priamry_font-weight_3;
              color: $blue_300;
              margin-top: 15px;
            }
          }
          a {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            border: $secondary_color solid 1px;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            &:hover {
              text-decoration: none;
            }
            @media screen and (max-width: 599px) {
              width: 100%;
            }
            p {
              text-transform: capitalize;
              font-size: $secondary_font_size_14;
              font-weight: $priamry_font-weight_3;
              color: $blue_300;
              margin-top: 15px;
            }
          }
          .active-buttons {
            a {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              border-color: $blue_300;
              color: $blue_300;
              margin-right: 8px;
              @media screen and (max-width: 599px) {
                width: 100%;
                padding: 5px 10px;
              }
              p {
                text-transform: capitalize;
                font-size: $secondary_font_size_14;
                font-weight: $priamry_font-weight_3;
                color: $blue_300;
                margin-top: 15px;
              }
            }
          }
          .whatsapp {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            @media screen and (max-width: 599px) {
              // align-items: center;
              margin-bottom: 0px;
            }
            .whatsapp-icon {
              @media screen and (max-width: 1024px) {
                display: none;
              }
              flex: 0 0 40%;
              display: flex;
              align-items: center;
              div {
                width: 90px;
              }
            }
            .whatsapp-icon-small {
              display: none;
              @media screen and (max-width: 1024px) {
                display: block;
                margin-right: 10px;
              }
            }
            .whatsapp-contact {
              flex: 0 0 60%;
              @media screen and (max-width: 1024px) {
                flex: 1;
              }
              p {
                font-size: $secondary_font_size_2;
                font-weight: $priamry_font-weight_2;
                color: $primary_text_color;
              }
              h5 {
                font-size: $secondary_font_size_16;
                font-weight: $priamry_font-weight_3;
                color: $primary_text_color;
              }
            }
          }
          &.on-process {
            button {
              width: 100%;
              height: 28px;
              background: $button_gray;
              border: $place_holder_color solid 1px;
              text-transform: capitalize;
              color: $place_holder_color;
              font-size: $secondary_font_size_14;
              font-weight: $priamry_font-weight_3;
              @media screen and (max-width: 599px) {
                display: none;
              }
            }
          }
          &.active {
            .active-buttons {
              display: flex;
              button {
                height: 28px;
                flex: 1;
                border: $secondary_color solid 1px;
                color: $blue_300;
                text-transform: capitalize;
                font-size: $secondary_font_size_14;
                font-weight: $priamry_font-weight_3;
                margin-right: 20px;
                @media screen and (max-width: 599px) {
                  display: none;
                }
              }
              .view-btn {
                margin-right: 5px;
                @media screen and (min-width: 600px) {
                  display: none;
                }
              }
              div {
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .integration-conversation-box {
          .whatsapp {
            display: flex;
            flex-direction: column !important;
            flex-flow: nowrap;

            h5 {
              font-size: $secondary_font_size_16;
              font-weight: $priamry_font-weight_3;
              color: $primary_text_color;
            }

            span {
              font-size: $secondary_font_size_2;
              font-weight: $priamry_font-weight_2;
              color: $primary_text_color;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .conversation-channel-details-wrapper {
      .conversation-channel-details-inner {
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        .channel-details-head {
          display: flex;
          justify-content: space-between;
          .channel-head-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            p {
              font-size: $secondary_font_size_16;
              color: $light_gray;
              font-weight: $priamry_font-weight_2;
            }
            div {
              display: flex;
              align-items: center;
              margin-left: 15px;
            }
          }
        }
        .channel-information {
          @media screen and (max-width: 768px) {
            width: 487px;
          }
          @media screen and (max-width: 600px) {
            width: 100%;
          }
          h5 {
            font-size: $secondary_font_size_16;
            color: $primary_color;
            font-weight: $priamry_font-weight_3;
            margin-top: 16px;
          }
          .channel-information-profile {
            display: flex;
            .information-profile-img {
              width: 112px;
              height: 112px;
              border-radius: 4px;
              overflow: hidden;
              border: $border_color_3 solid 1px;
              position: relative;
              div {
                position: relative;
                z-index: 2;
                pointer-events: none;
              }
              label {
                position: absolute;
                left: 0;
                z-index: 1;
              }
              img {
                border-radius: 4px;
              }
            }
            .information-profile-contents {
              flex: 1;
              margin-left: 16px;
              h6 {
                font-size: $secondary_font_size_16;
                color: $primary_color;
                font-weight: $priamry_font-weight_2;
              }
              p {
                font-size: $secondary_font_size_2;
                color: $primary_color;
                font-weight: $priamry_font-weight_2;
                span {
                  font-weight: $priamry_font-weight_3;
                  font-size: $secondary_font_size_2;
                }
              }
            }
          }
          .information-profile-form {
            label {
              font-size: $secondary_font_size_2;
              color: $primary_color;
              font-weight: $priamry_font-weight_2;
            }
          }
        }
        .agent-access-table {
          margin-top: 16px;
          margin-left: -8px;
          margin-right: -8px;
          margin-bottom: 16px;
          h5 {
            font-size: $secondary_font_size_16;
            color: $primary_color;
            font-weight: $priamry_font-weight_3;
            padding-left: 8px;
          }
          table {
            thead {
              th {
                padding: 12px 8px;
                font-size: $secondary_font_size_2;
                color: $primary_color;
                font-weight: $priamry_font-weight_3;
              }
            }
            tbody {
              td {
                padding: 12px 8px;
                font-size: $secondary_font_size_14;
                color: $primary_color;
                font-weight: $priamry_font-weight_2;
                div {
                  display: flex;
                }
              }
            }
          }
          .agent-access-table-container {
            scrollbar-color: $info_text_gray;
            scrollbar-width: thin;

            &::-webkit-scrollbar-track {
              background: rgba(255, 255, 255, 0.3);
            }
            &::-webkit-scrollbar {
              width: 5px;
              height: 5px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: $info_text_gray;
            }
          }
        }
      }
      .delete-btn {
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
      .delete-btn-icon {
        @media screen and (min-width: 601px) {
          display: none;
        }
        button {
          padding: 9px;
          svg {
            fill: $red_color;
            fill-rule: evenodd;
          }
        }
      }
    }
    .webhook-order-details-wrapper {
      padding: 32px;
      @media screen and (max-width: 992px) {
        padding: 16px;
      }
      @media screen and (max-width: 414px) {
        padding: 0px;
      }
      .webhook-order-details-inner {
        width: 535px;
        padding: 24px;
        background: $white_color;
        border-radius: 4px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        .webhook-order-details-head {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          @media screen and (max-width: 280px) {
            flex-wrap: wrap;
          }
          h5 {
            font-size: $secondary_font_size_16;
            font-weight: $priamry_font-weight_3;
            @media screen and (max-width: 438px) {
              font-size: 13px;
            }
          }
          .channel-head-right {
            display: flex;
            align-items: center;
            @media screen and (max-width: 280px) {
              justify-content: space-between;
              width: 100%;
            }
            p {
              font-size: $secondary_font_size_16;
              color: $light_gray;
              font-weight: $priamry_font-weight_2;
            }
            div {
              display: flex;
              align-items: center;
              margin-left: 15px;
              @media screen and (max-width: 438px) {
                margin-left: 5px;
                font-size: 13px;
              }
              @media screen and (max-width: 280px) {
                margin-left: 0px;
                margin-top: 10px;
              }
            }
          }
        }
        .webhook-order-details-forms {
          label {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
          }
          p {
            font-size: $secondary_font_size_16;
            font-weight: $priamry_font-weight_2;
            padding-bottom: 16px;
          }
          input {
            height: 40px;
            border: $border_color_3 solid 1px;
            margin-bottom: 16px;
            padding: 14px;
            font-size: $secondary_font_size_16;
            font-weight: $priamry_font-weight_2;
          }
          .token {
            display: flex;
            input {
              margin-bottom: 0;
              flex: 1;
              border-radius: 4px;
            }
            button {
              width: 40px;
              margin-left: 8px;
            }
          }
        }
        .delete-btn {
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
        .delete-btn-icon {
          @media screen and (min-width: 601px) {
            display: none;
          }
          button {
            padding: 9px;
            svg {
              fill: $red_color;
              fill-rule: evenodd;
            }
          }
        }
      }
    }
    .whatsapp-conversation-channel-wrapper {
      padding: 32px;
      @media screen and (max-width: 450px) {
        padding: 0px;
      }
      .conversation-channel-inner {
        width: 640px;
        padding: 24px;
        background: $white_color;
        border-radius: 4px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        @media screen and (max-width: 450px) {
          min-height: calc(100vh - 110px) !important;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        h5 {
          font-size: $secondary_font_size_16;
          font-weight: $priamry_font-weight_3;
        }
        .conversation-channel-form {
          label {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
          }
          input {
            font-size: $secondary_font_size_16;
            color: $primary_color;
            font-weight: $priamry_font-weight_2;
            padding-left: 16px;
            border: $border_color_3 solid 1px;
          }
        }
        p {
          font-size: $secondary_font_size_2;
          font-weight: $priamry_font-weight_2;
          color: $nav_icon_color;
        }
        ul {
          padding-left: 10px;
          margin-top: 10px;
        }
        li {
          font-size: $secondary_font_size_2;
          font-weight: $priamry_font-weight_2;
          color: $nav_icon_color;
          margin-left: 8px;
          font-family: $primary_font;
          line-height: 18px;
        }
        .conversation-channel-alert {
          background: $alert_bg_yellow;
          padding: 8px;
          display: flex;
          border-radius: 4px;

          li {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
            color: #8c6400;
          }
        }
        .accordion {
          margin: 0px 0 16px 0;
          h5 {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_3;
            color: $nav_icon_color;
          }
          p {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
            color: $nav_icon_color;
          }
        }
      }
    }
    .add-webhook-message-wrapper {
      padding: 32px;
      width: 528px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      @media screen and (max-width: 400px) {
        padding: 0px;
        height: calc(100vh - 110px);
      }
      .add-webhook-message-form {
        background: $white_color;
        border-radius: 4px;
        h5 {
          font-size: $secondary_font_size_16;
          font-weight: $priamry_font-weight_3;
          margin-bottom: 16px;
        }
        label {
          font-size: $secondary_font_size_2;
          font-weight: $priamry_font-weight_2;
        }
        input {
          padding: 14px;
          font-size: $secondary_font_size_16;
          border: $border_color_3 solid 1px;
        }
      }
    }
    .dashboard-home-section {
      width: 100%;
      .welcome-section {
        h3 {
          font-size: $secondary_font_size_32;
          font-weight: $priamry_font-weight_3;
          color: $primary_text_color_2;
          line-height: 1;
          margin-bottom: 5px;
          span {
            color: $blue_300;
            font-size: $secondary_font_size_32;
            font-weight: $priamry_font-weight_3;
            line-height: 1;
          }
        }
        p {
          font-size: $secondary_font_size_16;
          font-weight: $priamry_font-weight_2;
          color: $primary_text_color_2;
          @media screen and (max-width: 360px) {
            font-size: $secondary_font_size_2;
          }
        }
        .welcome-section-conversation {
          @media screen and (max-width: 280px) {
            flex-direction: column;
          }
          .conversation-item {
            @media screen and (max-width: 280px) {
              max-width: 100%;
            }
          }
          .conversation-block {
            background: $white_color;
            border-radius: 4px;
            padding: 16px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
            display: flex;
            @media screen and (max-width: 992px) {
              padding: 8px;
            }
            .block-text {
              h5 {
                font-size: $secondary_font_size_24;
                font-weight: $priamry_font-weight_3;
                color: $blue_300;
                line-height: 1;
              }
              p {
                font-size: $secondary_font_size_2;
                font-weight: $priamry_font-weight_2;
                color: $primary_text_color;
                @media screen and (max-width: 380px) {
                  font-size: $secondary_font_size;
                }
                @media screen and (max-width: 280px) {
                  font-size: $secondary_font_size_2;
                }
              }
            }
          }
        }
      }
      .monthly-summary {
        margin-top: 32px;
        h5 {
          font-size: $secondary_font_size_24;
          font-weight: $priamry_font-weight_3;
          @media screen and (max-width: 360px) {
            font-size: $secondary_font_size_20;
          }
        }
        p {
          font-size: $secondary_font_size_2;
          font-weight: $priamry_font-weight_2;
          color: $light_gray;
          span {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_3;
          }
        }
        .summary-block {
          background: $white_color;
          border-radius: 4px;
          padding: 16px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          overflow: hidden;
          p {
            font-size: $secondary_font_size_16;
            font-weight: $priamry_font-weight_2;
            color: $primary_text_color;
            svg {
              margin-left: 5px;
            }
          }
          h5 {
            font-size: $secondary_font_size_48;
            font-weight: $priamry_font-weight_3;
            color: $primary_text_color;
            @media screen and (max-width: 1280px) {
              font-size: $secondary_font_size_32;
            }
            @media screen and (max-width: 1024px) {
              font-size: $secondary_font_size_32;
            }
            @media screen and (max-width: 912px) {
              font-size: $secondary_font_size_32;
            }
          }
        }
      }
      .shop-conversation-channel {
        margin-top: 32px;
        h3 {
          font-size: $secondary_font_size_24;
          font-weight: $priamry_font-weight_3;
          color: $primary_text_color;
        }
        .channel-block {
          background: $white_color;
          border-radius: 4px;
          padding: 16px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 72px;
          p {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
          }
          h6 {
            font-size: $secondary_font_size_16;
            font-weight: $priamry_font-weight_3;
            line-height: 1;
            @media screen and (max-width: 1440px) {
              font-size: $secondary_font_size_14;
            }
          }
        }
      }
      .open-sections {
        width: 752px;
        padding: 16px;
        background: $white_color;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        margin-top: 16px;
        display: flex;
        @media screen and (max-width: 992px) {
          width: 100%;
        }
        .open-thumb {
          width: 80px;
          height: 80px;
          background: $border_color_2;
          margin-right: 16px;
        }
        .open-details {
          width: 100%;
          h4 {
            font-size: $secondary_font_size_20;
            font-weight: $priamry_font-weight_3;
            color: $blue_300;
          }
          button {
            border-color: $blue_300;
            color: $blue_300;
            text-transform: capitalize;
            font-size: $secondary_font_size_14;
            font-weight: $priamry_font-weight_3;
            padding: 0 30px;
            height: 28px;
            @media screen and (max-width: 600px) {
              padding: 0 15px;
            }
          }
          p {
            font-size: $secondary_font_size_2;
            font-weight: $priamry_font-weight_2;
            a {
              font-weight: $priamry_font-weight_3;
              margin-left: 5px;
              color: $blue_300;
            }
          }
          ul {
            padding-left: 20px;
            li {
              padding: 2px 0;
              font-size: $secondary_font_size_2;
              font-weight: $priamry_font-weight_2;
              color: $primary_text_color;
              font-family: $primary_font;
            }
          }
        }
      }
    }
    .quick-reply-section {
      background: #fafafa;
      min-height: calc(100vh - 110px);
      .tab-wrapper {
        .tab-section {
          padding: 32px;
          background: $border_color_3;
          height: 100%;
          @media screen and (max-width: 1024px) {
            padding: 16px;
          }
          .tab-section-inner {
            .quick-reply {
              width: 1088px;
              background: $white_color;
              border-radius: 4px;
              padding: 32px;
              @media screen and (max-width: 1180px) {
                width: 100%;
              }
              .quick-reply-top {
                h5 {
                  font-size: $secondary_font_size_20;
                  font-weight: $priamry_font-weight_3;
                  margin-bottom: 8px;
                }
                p {
                  font-size: $secondary_font_size_16;
                  font-weight: $priamry_font-weight_2;
                  a {
                    text-decoration: none;
                    color: $blue_300;
                  }
                }
              }
              .quick-reply-table-container {
                thead {
                  @media screen and (max-width: 600px) {
                    display: none;
                  }
                  th {
                    padding: 16px 8px 16px 0;
                  }
                }
                .quick-reply-table-body {
                  @media screen and (max-width: 600px) {
                    display: none;
                  }
                  td {
                    padding: 16px 8px 16px 0;
                    .action-btns {
                      display: flex;
                      align-items: center;
                      justify-content: space-evenly;
                    }
                  }
                }
                .m-quick-reply-table-body {
                  @media screen and (min-width: 601px) {
                    display: none;
                  }
                  tr {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    td {
                      width: 100% !important;
                      padding: 8px 0px 8px 0;
                      display: flex;
                      justify-content: space-between;
                      .message-content {
                        width: 60%;
                      }
                      .action-btns {
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;
                      }
                      &:first-child {
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .tab {
          padding: 0 32px;
          background: $white_color;
          @media screen and (max-width: 1024px) {
            padding: 0 16px;
          }
          .css-1aquho2-MuiTabs-indicator {
            height: 4px;
            border-radius: 5px 5px 0 0;
            background-color: $blue_300;
          }
          button {
            padding: 12px 0;
            margin-right: 32px;
            text-transform: capitalize;
            font-size: $secondary_font_size_14;
            font-weight: $priamry_font-weight_3;
            &.Mui-selected {
              color: $blue_300;
            }
          }
        }
      }
    }
    .add-quick-reply-section {
      width: 100%;
      padding: 16px 32px;

      @media screen and (max-width: 600px) {
        padding: 0;
        .add-quick-reply {
          min-height: calc(100vh - 110px) !important;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .add-quick-reply {
        width: 560px;
        background: $white_color;
        border-radius: 4px;
        padding: 30px 24px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        label {
          font-size: $secondary_font_size_14;
          font-weight: $priamry_font-weight_2;
          color: $primary_text_color;
        }

        input {
          width: 100%;
          height: 40px;
          border: $border_color_3 solid 1px;
          padding: 8px 16px;
          font-size: $secondary_font_size_16;
          color: $primary_text_color;
          border-radius: 4px;
          margin-bottom: 16px;

          &:focus-visible {
            outline-color: $blue_300;
          }
          &::placeholder {
            color: $place_holder_color;
            opacity: 1;
          }
        }
        .upload-text {
          @media only screen and (max-width: 599px) {
            margin-left: 0 !important;
          }
        }
      }
    }
    .add-message-section {
      width: 100%;
      padding: 32px;
      @media screen and (max-width: 992px) {
        padding: 16px;
      }
      @media screen and (max-width: 450px) {
        padding: 0;
      }
      .add-message {
        width: 578px;
        background: $white_color;
        border-radius: 4px;
        padding: 30px 24px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 540px) {
          width: 100%;
          min-height: calc(100vh - 110px) !important;
        }
        label {
          font-size: $secondary_font_size_2;
          font-weight: $priamry_font-weight_2;
          color: $primary_text_color;
        }

        input {
          width: 100%;
          height: 40px;
          border: $border_color_3 solid 1px;
          padding: 8px 16px;
          font-size: $secondary_font_size_16;
          color: $primary_text_color;
          border-radius: 4px;

          &:focus-visible {
            outline-color: $blue_300;
          }
          &::placeholder {
            color: $place_holder_color;
            opacity: 1;
          }
        }
        h5 {
          font-size: $secondary_font_size_16;
          font-weight: $priamry_font-weight_3;
        }
        .select {
          height: 42px;
        }
        .upload-text {
          @media only screen and (max-width: 599px) {
            margin-left: 0 !important;
          }
        }
      }
    }
    .message-details-section {
      width: 100%;
      padding: 32px;
      @media screen and (max-width: 992px) {
        padding: 16px;
      }
      .message-details {
        width: 560px;
        background: $white_color;
        border-radius: 4px;
        padding: 30px 24px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        .message-details-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          align-items: center;
          h4 {
            font-size: $secondary_font_size_16;
            font-weight: $priamry_font-weight_3;
          }
          .details-head-right {
            display: flex;
            align-items: center;
            div {
              display: flex;
            }
            span {
              margin-right: 8px;
            }
          }
        }
        label {
          font-size: $secondary_font_size_2;
          font-weight: $priamry_font-weight_2;
          color: $primary_text_color;
        }

        input {
          width: 100%;
          height: 40px;
          border: $border_color_3 solid 1px;
          padding: 8px 16px;
          font-size: $secondary_font_size_16;
          color: $primary_text_color;
          border-radius: 4px;

          &:focus-visible {
            outline-color: $blue_300;
          }
          &::placeholder {
            color: $place_holder_color;
            opacity: 1;
          }
        }
        h5 {
          font-size: $secondary_font_size_16;
          font-weight: $priamry_font-weight_3;
        }
        .select {
          height: 42px;
        }
        .delete-btn {
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
        .delete-btn-icon {
          @media screen and (min-width: 601px) {
            display: none;
          }
          button {
            padding: 9px;
            svg {
              fill: $red_color;
              fill-rule: evenodd;
            }
          }
        }
      }
    }
    .app-banner {
      padding: 32px 32px 0px 32px;
      div {
        display: block !important;
        img {
          object-fit: cover;
        }
      }
    }
    .apps-section {
      padding: 32px;
      .tab {
        padding: 0 32px;
        background: $white_color;
        @media screen and (max-width: 1024px) {
          padding: 0 16px;
        }
        .css-1aquho2-MuiTabs-indicator {
          height: 4px;
          border-radius: 5px 5px 0 0;
          background-color: $blue_300;
        }
        button {
          padding: 12px 0;
          margin-right: 32px;
          text-transform: capitalize;
          font-size: $secondary_font_size_14;
          font-weight: $priamry_font-weight_3;
          &.Mui-selected {
            color: $blue_300;
          }
        }
      }
      .tab-section {
        background: $white_color;
        .app-search {
          position: relative;
          margin-bottom: 24px;
          .search-icon {
            position: absolute;
            top: 0;
            left: 10px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          input {
            width: 100%;
            height: 40px;
            border-radius: 4px;
            border: $border_color_3 solid 1px;
            padding: 0 40px 0 55px;
          }
        }
        .apps-block {
          padding: 24px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          display: flex;
          align-items: center;
          h5 {
            font-size: $secondary_font_size_16;
            font-weight: $priamry_font-weight_3;
          }
          p {
            font-size: $secondary_font_size_14;
            font-weight: $priamry_font-weight_2;
            color: $light_gray;
          }
        }
      }
    }
    .download-app-section {
      width: 100%;
      padding: 32px;
      @media screen and (max-width: 992px) {
        padding: 16px;
      }
      .download-app {
        width: 560px;
        background: $white_color;
        border-radius: 4px;
        padding: 30px 24px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        h5 {
          font-size: $secondary_font_size_16;
          font-weight: $priamry_font-weight_3;
          color: $primary_text_color;
        }
        p {
          font-size: $secondary_font_size_14;
          font-weight: $priamry_font-weight_2;
          color: $light_gray;
          margin-top: 5px;
        }
        label {
          font-size: $secondary_font_size_2;
          font-weight: $priamry_font-weight_2;
          color: $primary_text_color;
        }

        input {
          width: 100%;
          height: 40px;
          border: $border_color_3 solid 1px;
          padding: 8px 16px;
          font-size: $secondary_font_size_16;
          color: $primary_text_color;
          border-radius: 4px;

          &:focus-visible {
            outline-color: $blue_300;
          }
          &::placeholder {
            color: $place_holder_color;
            opacity: 1;
          }
        }
      }
    }
    .app-details-section {
      padding: 32px;
      .back-btn {
        a {
          font-family: $primary_font;
          display: flex;
          align-items: center;
          font-size: $secondary_font_size_14;
          font-weight: $priamry_font-weight_2;
          color: $nav_hover_color;
          svg {
            margin-right: 8px;
          }
        }
      }
      .app-details {
        max-width: 1200px;
        padding: 32px;
        background: $white_color;
        border-radius: 4px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        margin-top: 32px;
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
        .details-header {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          .app-details-icon {
            padding: 20px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            display: inline-block;
            margin-right: 24px;
          }
          .app-details-text {
            h5 {
              font-size: $secondary_font_size_20;
              font-weight: $priamry_font-weight_3;
              color: $primary_text_color;
            }
            p {
              font-size: $secondary_font_size_14;
              font-weight: $priamry_font-weight_2;
              color: $nav_icon_color;
            }
            button {
              font-size: $secondary_font_size_16;
              font-weight: $priamry_font-weight_3;
              height: 32px;
              margin-right: 16px;
              margin-top: 5px;
            }
          }
        }
        .app-details-conents {
          h5 {
            font-size: $secondary_font_size_16;
            font-weight: $priamry_font-weight_3;
            color: $primary_text_color;
            border-bottom: $border_color_3 solid 1px;
            margin-bottom: 8px;
            padding-bottom: 8px;
          }
          h6 {
            font-size: $secondary_font_size_14;
            font-weight: $priamry_font-weight_3;
            color: $nav_icon_color;
            margin-bottom: 8px;
          }
          p {
            font-size: $secondary_font_size_14;
            font-weight: $priamry_font-weight_2;
            color: $light_gray;
            margin-bottom: 8px;
          }
          .app-detail-banner {
            margin: 16px 0;
            div {
              display: block !important;
              img {
                object-fit: cover;
              }
            }
          }
        }
      }
    }
    .channels-container {
      width: 100%;
      padding: 32px 24px;
      @media screen and (max-width: 992px) {
        padding: 24px 16px;
      }
    }
    .page-content-container {
      width: 100%;
      padding: 32px 24px;
      @media screen and (max-width: 992px) {
        padding: 24px 16px;
      }
    }
  }

  &.supervisor-page {
    .quick-reply {
      ul {
        li {
          padding-top: 3px !important;
          padding-bottom: 3px !important;
          cursor: pointer;

          p {
            font-weight: $priamry_font-weight_3;
          }
        }
      }
    }
    .main-chat-section {
      .chat-left {
        .chat-list-wrapper {
          @media screen and (max-width: 600px) {
            height: calc(100vh - 185px) !important;
          }
          @media screen and (min-device-width: 800px) and (max-device-width: 812px) and (orientation: landscape) {
            height: calc(100vh - 185px) !important;
          }
        }
      }
    }
  }

  .transfer-chat {
    .transfer-chat-modal {
      h2 {
        font-size: $secondary_font_size_1;
        color: $primary_text_color;
        font-weight: $priamry_font-weight_3;
        font-family: $primary_font;
      }
      .modal-chat-search {
        display: none;
      }
    }
  }
}
.main-container-static {
  min-height: 100vh;
  .static-header {
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  }
  .static-wrapper {
    display: flex;
    .left-nav {
      width: 235px;
      padding: 32px 16px;
      p {
        font-size: $secondary_font_size_20;
        color: $primary_text_color;
        font-weight: $priamry_font-weight_3;
        padding: 0 16px;
      }
    }
    .static-conents {
      display: flex;
      flex: 1;
      background: $button_gray;
      padding: 56px 32px;
      .welcome {
        h1 {
          font-size: $secondary_font_size_60;
          color: $primary_text_color;
          font-weight: $priamry_font-weight_3;
        }
      }
    }
  }
}

#basic-menu {
  .MuiMenu-paper {
    ul {
      .roll {
        font-family: $primary_font;
        font-size: $secondary_font_size_14;
        font-weight: $priamry_font-weight_3;
        margin: 7px 8px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 80px;
        position: relative;
        padding: 8px 16px;
        &::after {
          position: absolute;
          width: 100%;
          height: 1px;
          content: "";
          background: $border_color_3;
          left: 0;
          bottom: -8px;
        }
        &.owner {
          color: $nav_hover_color;
          background-color: $lite_blue_color;
        }
        &.supervisor {
          color: $blue_400;
          background-color: $blue_100;
        }
        &.agen {
          color: $green_400;
          background-color: $green_100;
        }
      }
    }
  }
}
#mobile-right-menu {
  &.small-view-menu {
    ul {
      li {
        color: $primary_text_color !important;
        justify-content: flex-end;
      }
      .opsi-head {
        color: $light_gray !important;
        text-align: right;
        padding-right: 17px;
        font-size: $priamry_font_size;
      }
    }
  }
}

#mobile-right-menu {
  &.small-view-menu {
    ul {
      li {
        color: $primary_text_color !important;
        justify-content: flex-end;
      }
      .opsi-head {
        color: $light_gray !important;
        text-align: right;
        padding-right: 17px;
        font-size: $priamry_font_size;
      }
    }
  }
}

.headerMenu {
  .MuiMenu-list {
    min-width: 156px;
  }
}
.opsichat-menu {
  .MuiMenu-list {
    min-width: 190px;
  }
}
.tab-section {
  .MuiMenu-list {
    min-width: 216px;
  }
}
.botMenu {
  .MuiMenu-list {
    min-width: 173px;
    li {
      &:hover {
        background: none;
        color: $nav_hover_color;
      }
    }
  }
}

.toast {
  display: flex;
  align-content: center;
  .toast-icon {
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .toast-text {
    h4 {
      margin: 0;
      font-size: $secondary_font_size_2;
      color: $nav_icon_color;
      font-weight: $priamry_font-weight_3;
    }
    p {
      margin: 4px 0 0 0;
      font-size: $secondary_font_size_2;
      color: $nav_icon_color;
      font-weight: $priamry_font-weight_2;
    }
  }
}
.message-conversation-popup {
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    padding: 9.5px 14px !important;
  }
  .upload-text {
    @media only screen and (max-width: 599px) {
      margin-left: 0 !important;
    }
  }
}
.search-products-list-popup {
  table {
    thead {
      tr {
        th {
          padding: 8px;
          font-size: $secondary_font_size_2;
          color: $primary_text_color;
          font-weight: $priamry_font-weight_3;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 8px;
          p {
            font-size: $secondary_font_size_16;
            color: $primary_text_color;
            font-weight: $priamry_font-weight_2;
          }
          span {
            font-size: $secondary_font_size_2;
            color: $light_gray;
            font-weight: $priamry_font-weight_2;
            display: block;
          }
        }
      }
    }
  }

  .search-product-details {
    h4 {
      font-size: $secondary_font_size_16;
      color: $primary_text_color;
      font-weight: $priamry_font-weight_3;
    }
    p {
      font-size: $secondary_font_size_16;
      color: $primary_text_color;
      font-weight: $priamry_font-weight_2;
    }
    span {
      font-size: $secondary_font_size_2;
      color: $light_gray;
      font-weight: $priamry_font-weight_2;
      display: block;
    }
  }
}

.rich-text-editor {
  border: $border_color_3 solid 1px;
  margin-bottom: 20px !important;
  .rdw-editor-wrapper {
    display: flex;
    flex-direction: column-reverse;

    .rdw-editor-main {
      padding: 16px;
      min-height: 100px;

      .public-DraftStyleDefault-block {
        margin: 0;
      }
    }
  }
  .rdw-editor-toolbar {
    border: none;
    background: $secondary_bg_color;
    margin-bottom: 0;
    .rdw-option-wrapper {
      border: none;
      background: none;
    }
  }
}
.transfer-modal-popup {
  @media only screen and (max-width: 560px) {
    width: 95% !important;
    padding: 12px !important;
  }
  .transfering-chat {
    p {
      font-size: $secondary_font_size_13;
      font-weight: $priamry_font-weight_2;
    }
    .transfering {
      margin: 24px 0;
      display: flex;
      justify-content: space-between;
      .transfer-from {
        display: flex;
        align-items: center;
        h5 {
          font-size: $secondary_font_size_13;
          font-weight: $priamry_font-weight_2;
          margin-left: 15px;
        }
      }
      .transfer-arrow {
        display: flex;
        align-items: center;
      }
      .transfer-to {
        display: flex;
      }
    }
  }
  Button {
    color: $secondary_text_color_2;
    width: 96px;
    @media only screen and (max-width: 380px) {
      width: 65px;
    }
    &.btn-fill {
      background: $blue_300;
      color: $white_color;
      width: 96px;
    }
  }
}
.app-btn-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-size: $secondary_font_size_14;
    font-weight: $priamry_font-weight_3;
    color: $primary_text_color;
  }
  span {
    font-size: $secondary_font_size_2;
    font-weight: $priamry_font-weight_2;
    color: $light_gray;
  }
}

.MuiTooltip-tooltip {
  // max-width: 210px;
  box-shadow: -2px 0px 9px -4px rgba(0, 0, 0, 0.72);
  -webkit-box-shadow: -2px 0px 9px -4px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: -2px 0px 9px -4px rgba(0, 0, 0, 0.72);
  padding: 8px;
}
textarea {
  font-family: $primary_font;
}
.PrivatePickersToolbar-root {
  .PrivatePickersToolbar-dateTitleContainer {
    @media only screen and (min-width: 821px) {
      justify-content: flex-start;
    }
  }
}

.MuiList-root {
  padding-top: 4px;
  padding-bottom: 4px;
}

.MuiDrawer-paperAnchorBottom {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.MuiTableCell-root {
  padding: 8px;
}

.text-link {
  color: $blue_300;
}

.Toastify__toast-container {
  width: fit-content !important;
  min-width: 320px !important;
  max-width: 420px !important;
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
}

.Toastify__toast {
  margin-left: auto;
  width: fit-content !important;
  min-width: 320px !important;
  max-width: 420px !important;
  min-height: 48px !important;
  @media screen and (max-width: 600px) {
    border-radius: 4px !important;
    margin-bottom: 16px !important;
  }
}

.Toastify__toast-container--top-right {
  @media screen and (max-width: 600px) {
    padding: 16px !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: $red_400 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: $green_300 !important;
}
